import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>【特定商取引法に基づく表記】</h1>
      <table
        bgcolor="#999999"
        border={0}
        cellPadding={9}
        cellSpacing={1}
        style={{ fontSize: "13px", textAlign: "left" }}
      >
        {" "}
        <tbody>
          <tr>
            <th bgcolor="#EEEEEE">運営統括責任者</th>
            <td bgcolor="#FFFFFF">後藤 薫</td>
          </tr>{" "}
          <tr>
            <th bgcolor="#EEEEEE">所在地</th>
            <td bgcolor="#FFFFFF">兵庫県神崎郡市川町西田中8-7</td>
          </tr>{" "}
          <tr>
            <th bgcolor="#EEEEEE">電話番号</th>
            <td bgcolor="#FFFFFF">090-4560-0001</td>
          </tr>{" "}
          <tr>
            <th bgcolor="#EEEEEE">メールアドレス</th>
            <td bgcolor="#FFFFFF">info@joy-navi.world</td>
          </tr>{" "}
          <tr>
            <th bgcolor="#EEEEEE">販売URL</th>
            <td bgcolor="#FFFFFF">https://book.joy-navi.world/</td>
          </tr>{" "}
          <tr>
            <th bgcolor="#EEEEEE">商品の販売価格</th>
            <td bgcolor="#FFFFFF">
              販売URLでご希望のツアーを選択することで参照ください
            </td>
          </tr>{" "}
          <tr>
            <th bgcolor="#EEEEEE">支払時期</th>
            <td bgcolor="#FFFFFF">
              お申し込みフォームを送信した段階でお支払いが発生します
            </td>
          </tr>{" "}
          <tr>
            <th bgcolor="#EEEEEE">支払方法</th>
            <td bgcolor="#FFFFFF">
              クレジットカード
              <br />
              現地での代金引換
              <br />
            </td>
          </tr>
          <tr>
            <th bgcolor="#EEEEEE">
              商品代金以外の
              <br />
              必要金額
            </th>
            <td bgcolor="#FFFFFF">
              ホテルまでの送迎代金/金額はお申し込みフォームでご希望のツアーを選択することで参照ください
            </td>
          </tr>{" "}
          <tr>
            <th bgcolor="#EEEEEE">お申込み有効期限</th>
            <td bgcolor="#FFFFFF">
              10日以内にお願いいたします。
              <br /> 10日間入金がない場合は、キャンセルとさせていただきます。
            </td>
          </tr>{" "}
          <tr>
            <th bgcolor="#EEEEEE">商品引渡し時期</th>
            <td bgcolor="#FFFFFF">
              決済完了後、24時間以内に申込受付完了のメールを送信いたします。
              <br />
              参加権利の移転は申込受付完了メールをお受け取りされた時点となります。申込受付完了メールが届かない場合は、お手数ですがメールにてお問い合わせください。
              <br />
              お問い合わせ先：info@joy-navi.world
              <br />
            </td>
          </tr>
          <tr>
            <th bgcolor="#EEEEEE">キャンセルについて</th>
            <td bgcolor="#FFFFFF">
              ツアー日の2日前まで：100%返金 <br />
              ツアー日の2日前以降：0%返金 <br />
              <br />
              振り込みにて返金を行います <br />
              振込手数料は差し引かさせていただきます <br />
              キャンセル後1週間以内に振り込まさせていただきます <br />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Layout>
)

export default NotFoundPage
